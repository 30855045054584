import axios from 'axios';
import Encoding from 'encoding-japanese';

$(document).on('turbolinks:load', function() {
  function handleFileSelect(evt) {
    evt.stopPropagation();
    evt.preventDefault();

    var files = evt.dataTransfer.files;

    let fileReader = new FileReader();
    let file = files[0];

    fileReader.readAsBinaryString(file);
    fileReader.onload = (e) =>{
      var result = e.target.result;
      // console.log(Encoding.detect(result));


      var array = str2Array(result);
      var uniArray = [];
      if (Encoding.detect(result) === "SJIS"){
        uniArray = Encoding.convert(array, 'UNICODE', 'SJIS');
      }else if(Encoding.detect(result) === "UTF8"){
        uniArray = Encoding.convert(array, 'UNICODE', 'UTF8');
      }
      // else if(Encoding.detect(result) === "ASCII"){
      //   uniArray = Encoding.convert(array, 'UNICODE', 'ASCII');
      // }
    
			var result = Encoding.codeToString(uniArray);
      var output = [];

      // var reader = fileReader.result
      // // console.log(reader)
      // var sjisArray = str2Array(reader);
      // // console.log(sjisArray)
      // var uniArray = Encoding.convert(sjisArray, 'UNICODE', 'SJIS');

      // console.log(uniArray)
			// var result = Encoding.codeToString(uniArray);
      // console.log(result)

      const output_wointdex = result.trim().split("\r\n").slice(1,101);
      // console.log(output_wointdex)
      // output.push(output_wointdex);
      var address_srr = output_wointdex.join('\r\n');
      document.getElementById('post_info').value = address_srr;

      check_csvs(address_srr);
    }
  }

  function str2Array(str) {
    var array = [],i,il=str.length;
    for(i=0;i<il;i++) array.push(str.charCodeAt(i));
    return array;
  }

  async function check_csvs(address_srr){
    const url = "https://anshin-kaitori.net/posts/post_axios";
    // const url = "http://localhost:3000/posts/post_axios";
    // axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
    // axios.defaults.headers['X-Requested-With'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const queries = {
      data: address_srr
    }

    // console.log(url, queries)

    await axios.post(url,
      {params: queries},
      {headers: {'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')}}
      ).then((response)=>{
        
        // console.log(response)

        var notice = interpret_notice(response.data, address_srr)

        // console.log(notice)
        document.getElementById('email_receiver_notice').innerHTML = notice;
      })

  }

  function interpret_notice(num, hash){  

    var address_array = num.data.flatMap(function(f) {return f.address});
    var name_array = num.data.flatMap(function(f) {return f.name});
    var company_array = num.data.flatMap(function(f) {return f.company});
    var check_array = num.data.flatMap(function(f) {return f.check});

    let address_count = address_array.filter(function(x){return x === true}).length;
    let name_count = name_array.filter(function(x){return x === true}).length;
    let company_count = company_array.filter(function(x){return x === true}).length; 

    let check_count = check_array.filter(function(x){return x === true}).length;

    if(num.data.length === check_count){
      let addressIsInput = document.getElementById('address_is_input');
      addressIsInput.value = true;

      titleIsInput();

      
      var outputlist_summary = `<div>データを読み込みました。読み込み件数：${num.data.length}件</div>`

      return outputlist_summary
      
    }else{
      
      let addressIsInput = document.getElementById('address_is_input');
      addressIsInput.value = false;
      titleIsInput();

      var output = [];
      var cnt = 0
      // console.log(num.data)
      hash.split("\r\n").map(function(x){
    
        var hash_bit = x.split(",")
        if(num.data[cnt].check === false){
          // console.log(hash_bit[2])
          output.push(`<li>データを適切に読み込めませんでした。ファイルが適切か確認して下さい。</li>`);
        }
        if(num.data[cnt].address === false){
          // console.log(hash_bit[0])
          output.push(`<li>"${hash_bit[0]}" は適切なアドレスではありません。</li>`);
        }
        if(num.data[cnt].name === false){
          // console.log(hash_bit[1])
          output.push(`<li>"${hash_bit[1]}" は適切な名前ではありません。</li>`);
        }
        if(num.data[cnt].company === false){
          // console.log(hash_bit[2])
          output.push(`<li>"${hash_bit[2]}" は適切な名称ではありません。</li>`);
        }
        cnt = cnt + 1;
      })
      // console.log(output);
      var outputlist = output.join("\r\n");
      var outputlist_summary = "<div>読み込みの失敗が発生しました。ファイルを確認してください。</div>"

      return `${outputlist_summary}<ul class="post_notice_list">${outputlist}</ul>`
    }

  }


  $(document).on('input', '#posts_title_input', function(){
    // let titleinput = document.getElementById('posts_title_input');
    // console.log(titleinput.value);
    titleIsInput();
  });

  function titleIsInput(){
    let inputElement = document.getElementById('posts_submit_button');
    let addressIsInput = document.getElementById('address_is_input');
    let titleinput = document.getElementById('posts_title_input');
    
    // console.log(addressIsInput.value === "true");
    // console.log(titleinput.value !== "");

    if((addressIsInput.value === "true") & (titleinput.value !== "")){
      inputElement.disabled = false;
    }else{
      inputElement.disabled = true;
    }
  }

  function handleDragOver(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    evt.dataTransfer.dropEffect = 'copy'; 
  }

  var dropZone = document.getElementById('drop_zone');
  if(dropZone){
    dropZone.addEventListener('dragover', handleDragOver, false);
    dropZone.addEventListener('drop', handleFileSelect, false);
  }
  function convertCSVtoArray(str){ 
    var result = [];
    var tmp = str.split("\n");

    for(var i=0;i<tmp.length;++i){
        result[i] = tmp[i].split(',');
    }

    alert(result[1][2]);
  }

  

})