$(document).on('turbolinks:load', function() {
  const pos = $('html');

  var body = $('body');
  var menu = $(".top-menu");
  var hamburger = $(".hamburger");
  var line = $(".line");
  var menuOpen;
    
  function openMenu(){
    menu.css("left", "0px");
    line.css("background", "#FFF");
    hamburger.addClass("is-open");
    body.addClass("top-menu_on");
    menuOpen = true;
  }
    
  function closeMenu(){
    menu.css("left", "-100vw");
    line.css("background", "#333");
    hamburger.removeClass("is-open");
    body.removeClass("top-menu_on");
    menuOpen = false;
  }
    
  function toggleMenu(){
    if (menuOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  }
      
  hamburger.on(function(){
      openMenu();
  });
  
  menu.on({
    mouseleave: function(){
      closeMenu();
    }
  });
    
  hamburger.on({
    click: function(){
      toggleMenu();
    }
  })  


})