$(document).on('turbolinks:load', function() {
  const axios = require('axios');

  clickBtnMonth = () =>{

    const evalButtonMonth = document.getElementById("evalButtonMonth").value;
    const getUserId = document.getElementById("get_user_id").innerHTML;
	  // document.getElementById("span3").textContent = str;

    if(evalButtonMonth){

      // console.log(evalButtonMonth)
      // console.log(getUserId);
      axios.get('/evaluations/ajax_evaluations',{
        params: {
          month: evalButtonMonth,
          user_id: Number(getUserId)
        }
      })
      .then(
        function cmnDone(data, textStatus, jqXHR) {
          
          r = document.getElementById('get_evaluations')
          r.innerHTML = data["data"]
        },
        function cmnFail(jqXHR, textStatus, errorThrown) {
          deferred_org.rejectWith(this, [jqXHR, textStatus, errorThrown]);
        }
      )
      .catch((e) => {
        console.trace(e);
      });
    }
  }

  // const clickBtnSubmitMonth = document.getElementById('clickBtnSubmitMonth');
  // const clickBtnSubmitMonth = document.getElementById('clickBtnSubmitMonth');


  clickBtnSubmitMonth = (e) => {
    e.preventdefault();
    // console.log(e)
  //   const evalButtonMonth = document.getElementById("evalButtonMonth").value;

  //   if(evalButtonMonth){

  //     axios.post('/evaluations/ajax_evaluations',{
  //       params: {
  //         month: evalButtonMonth
  //       }
  //     })
  //     .then(
  //       function cmnDone(data, textStatus, jqXHR) {

  //         r = document.getElementById('get_evaluations')
  //         r.innerHTML = data["data"]
  //       },
  //       function cmnFail(jqXHR, textStatus, errorThrown) {
  //         deferred_org.rejectWith(this, [jqXHR, textStatus, errorThrown]);
  //       }
  //     )
  //     .catch((e) => {
  //       console.trace(e);
  //     });
  //   }
  }

})