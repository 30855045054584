$(document).on('turbolinks:load', function() {
  
  $(".file_text_box0").click(function() {
    $(".default_file_upload_button").click();
  });

  $(".default_file_upload_button").on('change', function (e) {
    console.log(e.target.files);
    $(".file_text_box0").val(e.target.files[0].name);
    // 自動アップロード
    if (e.target.files[0].size > 0) {
        $(this).parents('form').submit();
    }
  });

  // $("input[type='file']").on('change',function(){
  //   var file = $(this).prop('files')[0];
  //   if(!($(".filename").length)){
  //     $("#input-group").append('<span class="filename"></span>');
  //   }
  //   $("#input-label").addClass('changed');
  //   $(".filename").html(file.name);
  // });

  // $(".fake_text_box").on("change", 'input[type= "file"].default_file_upload_button', function() {
  //   $('.default_file_upload_button').val($(this).val());
  // });

  // $(".default_file_upload_button").change(function(){
  //   // console.log($(this).val);
  // });

    //   var file = $(this).prop("files")[0];
    //   new_image_files.push(file);
    //   var reader = new FileReader();
    //   var img = $(`<li class= "add_img"><div class="img_area"><img id="previewed_image" class="image"></div></li>`);
  
  // // URLの取得
  // var url = location.href;
 
  // var preview = $("#preview");
  // var images = [];　  // 登録済画像と新規追加画像を全て格納する配列（ビュー用）
  // var registered_images_ids =[];　  // 登録済画像データだけの配列（DB用）
  // var new_image_files = [];　// 新規追加画像データだけの配列（DB用）

  // if(~url.indexOf("/entries/")){
  //   first_load();
  // } 

  // // console.log(images);
  // // console.log(registered_images_ids);

  // function first_load(){

  //   // 投稿画像のプレビュー表示
  //   gon.item_images.forEach(function(image, index){

  //     var img = $(`<li class= "add_img"><div class="img_area"><img id="previewed_image" class="image"></div></li>`);
  //     img.data("image", index);
  //     var btn_wrapper = $('<div class="btn_wrapper"><a class="btn_delete">削除</a></div>');
  
  //     // 画像に削除ボタンをつける
  //     img.append(btn_wrapper);
  //     binary_data = gon.item_images_binary_datas[index]

  //     // 表示するビューにバイナリーデータを付与
  //     img.find("img").attr({
  //       src: "data:image/jpeg;base64," + binary_data
  //     });

  //     // 登録済画像のビューをimagesに格納
  //     images.push(img);
  //     registered_images_ids.push(image.id)
  //   })
    
  //   if(images.length <= 8) {
  //   $('#preview').empty();
  //     $.each(images, function(index, image) {
  //       image.data('image', index);
  //       preview.append(image);
  //     })
  //     $("#upload-image").prop('disabled', false);
  //   }

  //   // 画像ドロップゾーンを反応しないようにする
  //   if (images.length == 8) {
  //     $("#upload-image").prop('disabled', true);
  //   }
  //   // var new_image = $(
  //   //   `<input multiple= "multiple" name="item_images[image][]" class="upload-image" data-image= ${images.length} type="file" id="upload-image">`
  //   // );
  //   // input_area.append(new_image);
  // };

  // /* 画像の新規追加 */
  // $("#edit_item .item__img__dropzone").on("change", 'input[type= "file"].upload-image', function() {
  //   var file = $(this).prop("files")[0];
  //   new_image_files.push(file);
  //   var reader = new FileReader();
  //   var img = $(`<li class= "add_img"><div class="img_area"><img id="previewed_image" class="image"></div></li>`);

  //   reader.onload = function(e) {
  //     var btn_wrapper = $('<div class="btn_wrapper"><a class="btn_delete">削除</a></div>');

  //     // 画像に編集・削除ボタンをつける
  //     img.append(btn_wrapper);
  //     img.find("img").attr({
  //       src: e.target.result
  //     });
  //     // console.log(e.target.result);
      
  //     // 画像のデータベースへの送信
  //     $.ajaxSetup({
  //       headers: {
  //         'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  //       }
  //     });

  //     passdata = {
  //       entry_id: gon.entry_id,
  //       image: e.target.result,
  //       id: gon.image_token,
  //     }

  //     $.ajax({
  //       url: '/entry_images/',
  //       type: 'POST',
  //       dataType: 'json',
  //       async: true,
  //       data: passdata,

  //       // beforeSend: function(){
  //       //   $('.loading').removeClass('hide');
  //       // }

  //     })
  //     .done(function(response){
  //       // console.log(response);
  //       // console.log(response["generated_img_id"]);
  //       registered_images_ids.push(response["generated_img_id"]);
  //       // console.log(registered_images_ids);

  //     })
  //     .fail(function(){
  //       // console.log("error");
  //     })
  //     .always(function(){
  //       // setTimeout(function(){
  //       // window.location.href = '/posts/show/' ;
  //       // },30);
  //       // console.log(data);
  //     });
  //   };

  //   reader.readAsDataURL(file);
  //   images.push(img);
    
  //   // 画像が8枚に満たない時はアップロード枠を表示する
  //   if (images.length <= 8) {
  //     $('#preview').empty();
  //     $.each(images, function(index, image){
  //       image.data('image', index);
  //       preview.append(image);
  //     })
  //     $("#upload-image").prop('disabled', false);
  //   }

  //   // 画像が8枚になったらアップロード枠を消す
  //   if (images.length == 8) {
  //     $("#upload-image").prop('disabled', true);
  //   }
  //   // var new_image = $(
  //   //   `<input multiple= "multiple" name="item_images[image][]" class="upload-image" data-image= ${images.length} type="file" id="upload-image">`
  //   // );
  //   // input_area.append(new_image);
  // })

  // // 画像の削除
  // $("#edit_item .item__img__dropzone").on('click', '.btn_delete', function() {
  //   var target_image = $(this).parent().parent();　// 削除ボタンを押した画像を取得
  //   var target_image_num = target_image.data('image');　// 削除画像のdata-image番号を取得
  //   target_image.remove();　// 対象の画像をビュー上で削除
  //   images.splice(target_image_num, 1);　// 対象の画像を削除した新たな配列を生成
    
  //   // console.log(registered_images_ids);
  //   // console.log(target_image_num);
  //   // console.log(images);

  //   $.ajaxSetup({
  //     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}
  //   });

  //   deletedata = {
  //     id: registered_images_ids[target_image_num],
  //   }

  //   $.ajax({
  //     url: '/entry_images/' + registered_images_ids[target_image_num] + "/",
  //     type: 'DELETE',
  //     dataType: 'json',
  //     async: true,
  //     data: deletedata,

  //     beforeSend: function(){
  //       // $("btn").attr("disabled","true"); 
  //       // $("div").text("Waiting...");
  //      },

  //   })
  //   .done(function(response){
  //     // console.log(response);

  //     // target_image_numが登録済画像の数以下の場合は登録済画像データの配列から削除、それより大きい場合は新たに追加した画像データの配列から削除
  //     // if (target_image_num < registered_images_ids.length) {
  //     registered_images_ids.splice(target_image_num, 1);
  //     // } else {
  //     //   new_image_files.splice((target_image_num - registered_images_ids.length), 1);
  //     // }
  //     // $("btn").removeAttr("disabled");

  //     if(images.length == 0) {
  //       $('input[type= "file"].upload-image').attr({
  //         'data-image': 0
  //       })
  //     }

  //     // 画像が8枚以下となるのでドロップゾーンを表示する
  //     if (images.length <= 8) {
  //       $('#preview').empty();
  //       $.each(images, function(index, image) {
  //         image.data('image', index);
  //         preview.append(image);
  //       })
  //       $("#upload-image").prop('disabled', false);
  //     }

  //   })
  //   .fail(function(){
  //     // console.log("error");
  //   })
  //   .always(function(){
  //     // setTimeout(function(){
  //     // window.location.href = '/posts/show/' ;
  //     // },30);
  //     // console.log(data);
  //   });
  // });


  //   // $(".upload.button").on('click', function () {
  //   //   var error = null;
  //   //   var orig_file = $('.file-input').prop('files')[0];
  //   //   var up_file = "bar/foo.png";
  //   //   console.log("ajax_calling....");
  //   //   console.log('https://snqdw01641.execute-api.ap-northeast-1.amazonaws.com/default/uploadfunc?file=' + up_file + '&type=' + orig_file.type);
  //   //   $.ajax({
  //   //     url: 'https://snqdw01641.execute-api.ap-northeast-1.amazonaws.com/default/uploadfunc?file=' + up_file + '&type=' + orig_file.type,
  //   //     type: 'GET'
  //   //   }).then(function (data) {
  //   //     console.log("ajax_then....");
        
  //   //     return $.ajax({
  //   //       url: data.signedurl,
  //   //       type: 'PUT',
  //   //       data: orig_file,
  //   //       contentType: orig_file.type,
  //   //       processData: false
  //   //     });
  //   //   }).then(function (data) {
  //   //     console.log("Upload success....");
  //   //     $(".uploaded").append($("<img />").attr("src", "https://anshin-kaitori-bucket.s3-ap-northeast-1.amazonaws.com/uploads/" + up_file));
  //   //   }).fail(function (data) {
  //   //     console.log("error....");
  //   //     error = data.message || data.statusText || data.errorText;
  //   //   })
  //   //     .always(function (jqXHR, textStatus) {
  //   //       if (error) {
  //   //         console.log("Error: " + error);
  //   //       }
  //   //     });

  //   //   return false;
  //   // });



  //   $(".upload.button").click(function() {
  //     $.ajax({
  //       type: "GET",
  //       url: "/tokens/get_aws_token",
  //       success: function(res) {
  //         var token = res.assume_role.credentials;
  //         AWS.config.region = "ap-northeast-1";
  //         AWS.config.credentials = new AWS.Credentials({
  //           sessionToken: token.session_token,
  //           secretAccessKey: token.secret_access_key,
  //           accessKeyId: token.access_key_id
  //         }); 
  //         var file = $('.file-input')[0].files[0];
  //         var bucket = new AWS.S3({params: {Bucket: "anshin-soudan-bucket"}});
  //         var params = {Key: "folder_name/" + file.name, ContentType: file.type, Body: file};
  //         bucket.upload(params, function (err, data) {
  //           console.log(err); // エラーがあれば表示される
  //         }).on('httpUploadProgress', function(evt) {
  //           console.log("完了率：" + parseInt((evt.loaded * 100) / evt.total) + '%');
  //         });
  //       }
  //     });
  //   }); 

})