if (document.documentElement.hasAttribute("data-turbolinks-preview")) {
  
}

$(document).on('turbolinks:load', function() {

  // エントリー画面の画像切り替え
  $(document).on('click', '.subsc',function(){
    var src = $(this).attr('src');
    $(".mainbsc").attr("src",src);
    return false;
  });

  // フラッシュ画面の時間経過による消滅
  $(".flash_show").delay(3000).slideUp();

  $(document).on('click', '.subsc',function(){
    var src = $(this).attr('src');
    $(".mainbsc").attr("src",src);
  });

  // メールの宛先の入力を不可にする
  // $(document).on('change', '#post_sendgroup', function(){
  //   var flg = $(this).val();
  //   if(flg != 0){
  //     $('.mail_to_selectbox').prop("disabled", true);
  //   }else{
  //     $('.mail_to_selectbox').prop("disabled", false);
  //   }

  // 送信先が変更されるとアドレス欄も変更される
  $(document).on('change', '#post_sendgroup', function(){
    var groupname = $(this).val();    
    // var array = [];

    console.log(groupname)

    $.ajax({
      url: 'get_address',
      data: {id: groupname},
      dataType: 'json'
    }).done((response) => {
      console.log(response)

      $("#js-searchable option").remove();
      if(response.data.length !== 0){
        for(i = 0; i < response.data.length ; i++){
          $("#js-searchable").append($("<option>").val(response.data[i].id).text(response.data[i].company + "："+response.data[i].name));
        }
        $("#js-searchable option").prop('selected', true);
      }

      // $("#js-searchable").val(response);
      //  $("#js-searchable").val(response).trigger("change");
      // return response;
    }).fail(error => {
      $("#js-searchable option").remove();
      // console.info(error)
      // return error
    })

  
    // for(i = 0; i < gon.address_records.length ; i++){
    //   console.log(gon.address_records)

      // if (gon.address_records[i][groupname] === "TRUE" ){
      //   val_list_string = "[" + gon.address_records[i][0];
      //   for (j = 1 ; j < gon.address_records[i].length ; j++){
      //     val_list_string = val_list_string + ', "' + gon.address_records[i][j] + '"';
      //   } 
      //   val_list_string = val_list_string + ']';
      //   // var element = document.getElementById("js-searchable").options;
      //   array.push(val_list_string)
      //   console.log()
      // }
    // }

  //  $("#js-searchable").val(response.data).trigger("change");
  })


  // try{
  //   gon.task_1[1].forEach(item =>{
  //     console.log(item)
  //     `item`
  //   })

  //   gon.task_1[1].forEach(item =>{
  //     console.log(item)
  //   })
  // }catch (err) {

  // }


  var height=window.innerHeight;
  document.documentElement.style.setProperty( '--vh', height/100 + 'px');

  var width=window.innerWidth;
  document.documentElement.style.setProperty( '--vw', width/100 + 'px');
  
  window.addEventListener('orientationchange', () => {
    var height=window.innerHeight;
    document.documentElement.style.setProperty( '--vh', height/100 + 'px');
  
    var width=window.innerWidth;
    document.documentElement.style.setProperty( '--vw', width/100 + 'px');
  
  });

  window.addEventListener('resize', () => {
    var height=window.innerHeight;
    document.documentElement.style.setProperty( '--vh', height/100 + 'px');
  
    var width=window.innerWidth;
    document.documentElement.style.setProperty( '--vw', width/100 + 'px');
  
  });
  
})
