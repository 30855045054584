$(document).on('turbolinks:load', () => {

   $('select[name="search_select"] option:selected').removeAttr('selected');
   $('select[name="search_select"]').val("search_items");

   $('#search_form_select').val("search_items");
  //  console.log($('#search_form_select').val());
  //  console.log($('#search_form_select').val());

  $('[name=search_select]').change(function() {
    var val = $('[name=search_select]').val();
    if(val === "search_items"){
      $('#items_search_form_container').removeClass("display_hide_forms");
      $('#users_search_form_container').addClass("display_hide_forms");
      $('#assistants_search_form_container').addClass("display_hide_forms");
      $('#names_search_form_container').addClass("display_hide_forms");

    }else if(val === "search_users"){

      $('#items_search_form_container').addClass("display_hide_forms");
      $('#users_search_form_container').removeClass("display_hide_forms");
      $('#assistants_search_form_container').addClass("display_hide_forms");
      $('#names_search_form_container').addClass("display_hide_forms");

    }else if(val === "search_assistants"){

      $('#assistants_search_form_container').removeClass("display_hide_forms");
      $('#users_search_form_container').addClass("display_hide_forms");
      $('#items_search_form_container').addClass("display_hide_forms");
      $('#names_search_form_container').addClass("display_hide_forms");
    
    }else if(val === "search_names"){

      $('#assistants_search_form_container').addClass("display_hide_forms");
      $('#users_search_form_container').addClass("display_hide_forms");
      $('#items_search_form_container').addClass("display_hide_forms");
      $('#names_search_form_container').removeClass("display_hide_forms");
    }

    console.log($('#search_form_select').val());
    $('#query_names').val("");
    $('#query_assistants').val("");
    $('#query_item').val("");
    $('#query_user').val("");
  });


});