$(document).on('turbolinks:load', function() {

  $("#show_purchase_modal").click(function(){
    $(".purchase_confirmation").css({"display":"block"});
    $("#fadeLayer_purchase").css({"display":"block"});
  });

  $("#show_nairan_modal").click(function(){
    $(".nairan_confirmation").css({"display":"block"});
    $("#fadeLayer_nairan").css({"display":"block"});
  });


  $("#fadeLayer_nairan").click(function(){
    $(".nairan_confirmation").css({"display":"none"});
    $("#fadeLayer_nairan").css({"display":"none"});
  })


  $("#fadeLayer_purchase").click(function(){
    $(".purchase_confirmation").css({"display":"none"});
    $("#fadeLayer_purchase").css({"display":"none"});
  })

  // $(".nairan_cancel_btn").click(function(){
  //   $(".nairan_confirmation").css({"display":"none"});
  //   $("#fadeLayer").css({"display":"none"});
  // })

  // $("#show_purchase_modal").click(function(){
  //   $("#description_in_modal").css({"display":"block"});
  // })

  // $("#show_purchase_modal").click(function(){
  //   $("#description_in_modal").css({"display":"block"});
  // })

});