require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")
require('jquery')
require('select2')

import '@fortawesome/fontawesome-free/js/all';
import "core-js/stable";
import "regenerator-runtime/runtime";

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);

require("trix")
require("@rails/actiontext")

import '../stylesheets/actiontext';
import '../stylesheets/admin_manual';
import '../stylesheets/algolia';
import '../stylesheets/application';
import '../stylesheets/css_topmenu';
import '../stylesheets/dropdownmenu';
// import '../stylesheets/fullcalendar';
// import '../stylesheets/fullcalendar_additional';
// import '../stylesheets/fullcalendar_mobile';
import '../stylesheets/mypage';
import '../stylesheets/recharts';
import '../stylesheets/sidebar';
import '../stylesheets/uikit-admin_items';
import '../stylesheets/uikit-application';
import '../stylesheets/events';
import '../stylesheets/taskcard';
import '../stylesheets/taskitem';
import '../stylesheets/email_addresses';
import '../stylesheets/posts';
import '../stylesheets/edit_auth';
import '../stylesheets/evaluations';
import '../stylesheets/user';
import '../stylesheets/search_select_detail';

import '../javascripts/admin_item';
import '../javascripts/admin_items_search_box';
import '../javascripts/application';
import '../javascripts/dropdownjs';
import '../javascripts/fileupload';
import '../javascripts/reservation';
import '../javascripts/suggestionForm';
import '../javascripts/topmenu';
import '../javascripts/posts';
import '../javascripts/monthSelect';
import '../javascripts/user_search';
import '../javascripts/assistant_search';
import '../javascripts/search_select_detail';
import '../javascripts/name_search';

import 'bootstrap/dist/js/bootstrap';

$(document).on('turbolinks:load', function() {
  $("#js-searchable").select2({
    placeholder: "クリックして選択",
    allowClear: true
  });
})

require.context('../images', true, /\.(png|jpg|jpeg|svg)$/);

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);