$(document).on('turbolinks:load', function() {

  const axios = require('axios');

  $(document).on('change', '#category_select', function(){
    sub_category_select_reload();
  });

  function sub_category_select_reload(){
    $('#sub_category_select option').remove();
    var o = $('#category_select').val();
    var target = gon.subcategory_list[0][o-1];

    const transpose = a => a[0].map((_, c) => a.map(r => r[c]));

    for(var i = 0; i < target.length ;i++ ){
      let op = document.createElement("option");
      op.value = transpose(target)[1][i];
      op.text = transpose(target)[0][i];
      document.getElementById("sub_category_select").appendChild(op);
      // console.log(op);
    }    
  }

  $(document).on('click', '.edit_admin_topic_javascript', function(){
      let className = $(this).attr('class').split(" ").slice(-1);
      edit_button_display(className);
  });
  
  function edit_button_display(class_tag){
    edit_button_hide();
    let targetclassname = "." + class_tag+"-form"
    // console.log(targetclassname);
    $(targetclassname).addClass("admin_topic_display");
  }

  $(document).on('click', '.admin_topics-edit_close_button', function(){
    edit_button_hide();
  });
  
  function edit_button_hide(){
    $('.admin_topic_edit_cover').removeClass("admin_topic_display");
  }
});


$(document).on('turbolinks:load', function() {

  $(document).on('click', '.edit_stakeholder_javascript', function(){
    let className = $(this).attr('class').split(" ").slice(-1);
    stakeholder_edit_button_display(className);
  });

  function stakeholder_edit_button_display(class_tag){
    stakeholder_edit_button_hide();
    let targetclassname = "." + class_tag+"-form";
    // console.log(targetclassname);
    $(targetclassname).addClass("stakeholder_edit_form_display");
    // $("#stakeholders_new_form").addClass("stakeholders_new_form_display_none");
  }

  $(document).on('click', '.stakeholder_edit_close_button', function(){
    stakeholder_edit_button_hide();
  });

  function stakeholder_edit_button_hide(){
    $('.stakeholder_edit_cover').removeClass("stakeholder_edit_form_display");
  }

});

document.addEventListener('turbolinks:load', () => {
  const axios = require('axios');

  $('#admin_items_tabs_seller').on('click',function(e){
    // e.preventDefault();

    var admin_item_id = gon.admin_item_id;
    axios.get('/admin_items/ajax_seller',{
      params: {
        id: admin_item_id
      }
    })
    .then(
      function cmnDone(data, textStatus, jqXHR) {
        r = document.getElementById('admin_item_seller')
        r.innerHTML = data["data"]
      },
      function cmnFail(jqXHR, textStatus, errorThrown) {
        deferred_org.rejectWith(this, [jqXHR, textStatus, errorThrown]);
      }
    )
    .catch((e) => {
      console.trace(e);
    });
  });
})

document.addEventListener('turbolinks:load', () => {
  const axios = require('axios');

  $('#admin_items_tabs_progress').on('click',function(e){
    // e.preventDefault();

    var admin_item_id = gon.admin_item_id;
    axios.get('/admin_items/ajax_progress',{
      params: {
        id: admin_item_id
      }
    })
    .then(
      function cmnDone(data, textStatus, jqXHR) {
        r = document.getElementById('admin_item_progress')
        r.innerHTML = data["data"]
      },
      function cmnFail(jqXHR, textStatus, errorThrown) {
        deferred_org.rejectWith(this, [jqXHR, textStatus, errorThrown]);
      }
    )
    .catch((e) => {
      console.trace(e);
    });
  });
})

document.addEventListener('turbolinks:load', () => {
  const axios = require('axios');

  $('#admin_items_tabs_checklist').on('click',function(e){
    // e.preventDefault();

    var admin_item_id = gon.admin_item_id;
    axios.get('/admin_items/checklist',{
      params: {
        id: admin_item_id
      }
    })
    .then(
      function cmnDone(data, textStatus, jqXHR) {
        r = document.getElementById('admin_item_checklist');
        r.innerHTML = data["data"];
      },
      function cmnFail(jqXHR, textStatus, errorThrown) {
        deferred_org.rejectWith(this, [jqXHR, textStatus, errorThrown]);
      }
    )
    .catch((e) => {
      console.trace(e);
    });
  });

})

document.addEventListener('turbolinks:load', () => {

  const axios = require('axios');
  $('#admin_items_tabs_order').on('click',function(e){
    e.preventDefault();

    var admin_item_id = gon.admin_item_id;
    axios.get('/admin_items/ajax_order',{
      params: {
        id: admin_item_id
      }
    })
    .then(
      function cmnDone(data, textStatus, jqXHR) {
        r = document.getElementById('admin_item_order')
        r.innerHTML = data["data"];
      },
      function cmnFail(jqXHR, textStatus, errorThrown) {
        deferred_org.rejectWith(this, [jqXHR, textStatus, errorThrown]);
      }
    )
    .catch((e) => {
      console.trace(e);
    });
  });
});

document.addEventListener('turbolinks:load', () => {
  var arr = gon.stakeholder_ids;
  // console.log(arr)
  if(arr){

    for(let i = 0 ; i < arr.length ; i++ ){ 
      // console.log(arr[i])
      // console.log(`#admin_items_childtabs_${arr[i]}`)

      $(document).on('click', `#admin_items_childtabs_${arr[i]}`, function(e){
      // $(`#admin_items_childtabs_${arr[i]}`).on('click',function(e){
        // e.preventDefault();
          (function(target_){
            const axios = require('axios');
            // console.log(`#admin_items_childtabs_${target_}`)
            axios.get('/stakeholders/ajax_show',{
              params: {
                id: target_
              }
            })
            .then(
              function cmnDone(data, textStatus, jqXHR) {
                r = document.getElementById(`stakeholder_child_${target_}`)
                r.innerHTML = data["data"]
              },
              function cmnFail(jqXHR, textStatus, errorThrown) {
                deferred_org.rejectWith(this, [jqXHR, textStatus, errorThrown]);
              }
            )
            .catch((e) => {
              console.trace(e);
            });

          }(arr[i]));   
      // ajax_item_reload(arr[i])
      }); 
    
      // }(arr[i])); 
    }
  }
});

// document.addEventListener('turbolinks:load', () => {
//   // var stakeholder_ids;
//   var stakeholders_ids = gon.stakeholders_ids;

//   console.log(stakeholders_ids)
//   if(stakeholders_ids){
//     for(let i = 0; i < stakeholders_ids.length ; i++){ 
//       let target = stakeholders_ids[i];
//       console.log(`#stakeholder_edit_${stakeholders_ids[i]}`)
//       $(`#stakeholder_edit_${stakeholders_ids[i]}`).on('click',function(e){
//         e.preventDefault();
//         (function(target_) {
//           console.log(target_)
//           $.ajax({
//             url: `/stakeholders/ajax_edit`, 
//             type: 'GET',
//             dataType: "html",
//             data:{
//               id: target_
//             },
//           }).done((response) => {
//             console.log(response)
//             r = document.getElementById(`stakeholder_child_${target_}`)
//             r.innerHTML = response
//           }).fail((err) => {})
//         }(target));    
//       });
//     }
//   }
// })