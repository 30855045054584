document.addEventListener('turbolinks:load', () => {

  const algoliasearch = require('algoliasearch');
  const autocomplete = require('autocomplete.js');

  var application_id = gon.application_id;
  var search_only_api_key = gon.search_only_api_key;

  const config = {
    application_id: application_id,
    search_only_api_key: search_only_api_key,
  };

  var client = algoliasearch(config.application_id, config.search_only_api_key);
  var index = client.initIndex('User');
  
  function newHitsSource(index, params) {
    return function doSearch(query, cb) {
      index
        .search(query, params)
        .then(function(res) {
          cb(res.hits, res);
        })
        .catch(function(err) {
          console.error(err);
          cb([]);
        });
    };
  }

  autocomplete('#query_user', { hint: false }, [
    {
      source: newHitsSource(index, { hitsPerPage: 5 }),
      displayKey: 'first',
      templates: {
        suggestion: function(suggestion) {
          return `${suggestion._highlightResult.first.value} ${suggestion._highlightResult.last.value}`;
        }
      }
    }
  ]).on('autocomplete:selected', function(event, suggestion, dataset, context) {
    var name = `${suggestion.first} ${suggestion.last}`;
    // console.log(`${suggestion.first} ${suggestion.last}`)
    if (context.selectionMethod === 'click') {
      window.location.assign(`/admin_items/search_user?query=${name}&button=`);
      return;
    }
  });
})