$(document).on('turbolinks:load', function() {

  const axios = require('axios');

  $(document).on('change', '#category_select_detail', function(){
    sub_category_select_detail_reload();
  });

  function sub_category_select_detail_reload(){
    $('#sub_category_select_detail option').remove();
    var o = $('#category_select_detail').val();
    console.log(o)
    console.log(gon.subcategory_list)
    var target = gon.subcategory_list[0][o-1];

    const transpose = a => a[0].map((_, c) => a.map(r => r[c]));
    console.log(target)
    for(var i = 0; i < target.length ;i++ ){
      let op = document.createElement("option");
      op.value = transpose(target)[1][i];
      op.text = transpose(target)[0][i];
      document.getElementById("sub_category_select_detail").appendChild(op);
      // console.log(op);
    }    
  }
})