$(document).on('turbolinks:load', function() {

  // ボタンhover
  $('.dropdown').hover(() => {
    $(".dropdown").addClass("dropdown_hover_select");
    $(".dropdown_hover_select svg").addClass("svg_pink");
  }, () => {
    $(".dropdown").removeClass("dropdown_hover_select");
    $(".dropdown_hover_select svg").removeClass("svg_pink");
  });

  // // ヘッダドロップダウン
  // $('.dropdown').on('click', () => {
  //   // console.log("a")
  //   const pos = $('.dropdown').offset();
  //   $('.dropdown_menu').offset({
  //     top: 60,
  //     left: pos.left - 130
  //   });

  //   $(".dropdown_menu ul").removeClass("dropdown_menu_hide");
  //   $(".dropdown_menu li").show();
  // // }, () => {
  // //   $(".dropdown_menu ul").addClass("dropdown_menu_hide");
  // //   $(".dropdown_menu li").hide();
  // });

  // $('.dropdown').on('click', toggleMenu);
  // $('.dropdown').on('click', () => {
  // }
  // $('.dropdown_menu').hover(function(){
  //   $(".dropdown_menu ul").removeClass("dropdown_menu_hide")
  //   $(".dropdown_menu li").show();
  // }, function(){
  //   $(".dropdown_menu ul").addClass("dropdown_menu_hide");
  //   $(".dropdown_menu li").hide();
  // });

  // function toggleMenu(){
  //   // var $self = $(this);
  //   // console.log($self)
  // }

  function fadeOutMenu(){
    $(".dropdown_menu ul").addClass("dropdown_menu_hide");
    $(".dropdown_menu li").hide();
  }

  $('.dropdown_menu li').on('click', () => {
    $(".dropdown_menu ul").removeClass("dropdown_menu_hide");
    $(".dropdown_menu li").show();
    // location.reload();
    
  });

  $(document).on('click touchend', function(e) {
    e.stopPropagation()
    if ((!$(e.target).closest('.dropdown').length) && (!$(e.target).closest('.dropdown_menu').length)) {
      fadeOutMenu();

    }else if($(e.target).closest('.dropdown').length){
      if ($(".dropdown_menu ul").hasClass("dropdown_menu_hide")){

        const pos = $('.dropdown').offset();
        $('.dropdown_menu').offset({
          top: 60,
          left: pos.left - 130
        });
        $(".dropdown_menu ul").removeClass("dropdown_menu_hide");
        $(".dropdown_menu li").show();

      }else{
        fadeOutMenu();

      }
    }
  });
});